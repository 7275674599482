import React from "react";
import "./Credits.css";

const Credits = () => {
    return (
        <div className="credits">
            <p>
                Developed by{" "}
                <a
                    href="https://www.virtualarcsoftware.com/"
                    className="text-blue-500 underline"
                >
                    Virtual Arc Software
                </a>
            </p>
        </div>
    );
};

export default Credits;